import configurations from '@next-app/config/configurations';
import urlMapping from '@next-app/config/urlMapping';
import { isBrowser } from './common-utils';
import { clearAuthInfo, storeAuthInfo } from './authenticationUser';
import { Token, TokenResponseError } from '@next-app/interface/Login';
import { permanentRedirect, RedirectType } from 'next/navigation';

type FetchParams = {
  endpoint: string;
  options?: any;
  params?: any;
  body?: any;
  headers?: any;
  isExternal?: boolean;
  cache?: string;
  tags?: string[];
  signal?: AbortController | null;
};

/**
 * Fetches the access token asynchronously.
 *
 * @return {Promise<Token | void>} The fetched access token or void.
 */
const fetchAccessToken = async (): Promise<Token | undefined> => {
  if (!isBrowser()) {
    return;
  }

  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    return;
  }
  const refreshTokenRequest = {
    client_id: 'login-app',
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };

  const url = localStorage.getItem('authIntegrationInfoUrl');

  if (!url) {
    return;
  }

  try {
    const refreshTokenResponse: Token & TokenResponseError = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(refreshTokenRequest).toString(),
    }).then((response) => response.json());

    if (refreshTokenResponse?.error) {
      console.log(
        'Error occurred in fetching new access token: ',
        refreshTokenResponse.error,
      );
    } else {
      return refreshTokenResponse;
    }
  } catch (error) {
    console.log('Exception occurred in fetching new access token: ', error);
  }
};

export const makeFetchCall = async (fetchParams: FetchParams): Promise<any> => {
  try {
    const { baseUrl } = configurations;
    const {
      endpoint,
      params = {},
      options = {},
      body = {},
      headers = {},
      isExternal = false,
      cache,
      tags,
      signal,
    } = fetchParams;

    const isParams = Object.keys(params).length > 0;
    const isBody = Object.keys(body).length > 0;

    let requestURL = isExternal
      ? endpoint
      : endpoint.startsWith('http')
        ? endpoint
        : `${baseUrl}${endpoint}`;
    if (isParams) {
      requestURL = decodeURIComponent(
        `${requestURL}?${new URLSearchParams(params)}`,
      );
    }

    if (isBrowser()) {
      const storedToken = localStorage.getItem('accessToken');
      const profileId = localStorage.getItem('profileId');
      if (storedToken) {
        headers['Authorization'] = `Bearer ${storedToken}`;
        if (profileId) {
          headers['profileId'] = profileId;
        }
      }
    }

    headers['Accept'] = 'application/json';
    headers['isReact'] = 'true';
    headers['Content-Type'] = headers['Content-Type'] || 'application/json';

    if (!isBrowser()) {
      headers['BlazeMeterKey'] = 'f472f34c-d105-45c8-b3b0-801f016e657e';
    }

    const methodType = options?.method || 'GET';

    const response = await fetch(requestURL, {
      method: methodType,
      cache,
      ...(tags && { next: { tags } }),
      ...(isBody && { body: JSON.stringify(body) }),
      ...options,
      headers,
      signal: signal?.signal,
    });
    if (fetchParams.options?.retries) {
      fetchParams.options.retries--;
    }

    if (!response.ok) {
      const requestSize = new TextEncoder().encode(
        JSON.stringify({ headers }),
      ).length;

      console.log(
        `Request status:  ${response.status}, at url ${requestURL}, and header size: ${requestSize} bytes, and headers: ${JSON.stringify(headers, null, 2)}`,
      );

      if (response.status === 401) {
        const token: Token | undefined = await fetchAccessToken();

        // update new token values in local storage
        if (token !== undefined && fetchParams.options?.retries !== 0) {
          storeAuthInfo(token);

          // resend the previous call
          return makeFetchCall(fetchParams);
        } else {
          // if token is undefined
          clearAuthInfo();
          return makeFetchCall(fetchParams);
        }
      } else if (response.status === 409) {
        const sessionConfRes: any = await makeFetchCall({
          endpoint: urlMapping.sessionConfirmationNumberUrl,
        });

        return sessionConfRes;
      } else {
        return response.statusText;
      }
    }

    const data = await response.json();
    // Do not print api calls in the browser
    // if (!isBrowser()) {
    //   try {
    //     const logObj = {
    //       headers: combinedHeaders,
    //       requestURL,
    //       fetchParams,
    //       response: data,
    //     };
    //     console.log('API Log: ', JSON.stringify(logObj));
    //   } catch (e) {
    //     console.log('logging exception: ', e);
    //   }
    // }

    if (
      data?.status === 301 &&
      data?.action === 'redirect' &&
      data?.redirectUrl
    ) {
      permanentRedirect(data?.redirectUrl, RedirectType.replace);
    }

    return data;
  } catch (error) {
    if (error instanceof Error && error.name === 'AbortError') {
      console.log('Request canceled');
    } else {
      console.error('makeFetchCall :: Catch :: Failed to fetch data:', error);
    }
    throw error;
  }
};

export const makeSyncAPICall = (fetchParams: FetchParams) => {
  try {
    const { baseUrl } = configurations;
    const {
      endpoint,
      params = {},
      options = {},
      body = {},
      isExternal = false,
    } = fetchParams;

    const methodType = options?.method || 'GET';
    const isParams = Object.keys(params).length > 0;

    let requestURL = isExternal
      ? endpoint
      : endpoint.startsWith('http')
        ? endpoint
        : `${baseUrl}${endpoint}`;
    if (isParams) {
      requestURL = decodeURIComponent(
        `${requestURL}?${new URLSearchParams(params)}`,
      );
    }

    const xhr = new XMLHttpRequest();
    const xhrReq = () => {
      xhr.open(methodType, requestURL, false);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('isReact', 'true');
      xhr.setRequestHeader('Content-Type', 'application/json');

      if (methodType.toUpperCase() === 'POST') {
        xhr.send(JSON.stringify(body));
      } else if (methodType.toUpperCase() === 'GET') {
        xhr.send();
      }

      if (xhr.status === 200) {
        console.log('Sync response : ', xhr.responseText);
        return JSON.parse(xhr.responseText);
      } else {
        return { Error: xhr.status };
      }
    };

    let response = xhrReq();

    console.log('Sync error 1------ : ', xhr.responseText);
    if (xhr.status === 409) {
      response = xhrReq();
      console.log('Sync error 2------ : ', xhr.responseText);
    }

    return response;
  } catch (error: any) {
    console.error(error.message);
  }
};
